import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { Link, useStaticQuery, graphql } from "gatsby"

import Navigation from "./Navigation"
import Footer from "./Footer"

const Layout = ({ children, whiteLogo = false, uid = "", colorTheme = "light" }) => {
  useEffect(() => {
    try {
      const UIkit = require("uikit/dist/js/uikit")
      const Icons = require("uikit/dist/js/uikit-icons")
      UIkit.use(Icons)
    } catch (e) {
      console.error("UIKit ERROR", e)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      prismicWebsiteLayout {
        data {
          copyright {
            raw
          }
          body {
            ... on PrismicWebsiteLayoutBodyNavigationItem {
              id
              items {
                label
                link
                level
              }
              primary {
                section_label
                section_link
              }
            }
          }
          body1 {
            ... on PrismicWebsiteLayoutBody1NavigationItem {
              id
              slice_type
              items {
                label
                link
                level
              }
              primary {
                section_label
                section_link
              }
            }
            ... on PrismicWebsiteLayoutBody1InfoSection {
              id
              slice_type
              primary {
                section_label
                section_content {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `)

  const navigationData = data.prismicWebsiteLayout.data.body
  const footerData = data.prismicWebsiteLayout.data.body1
  const { copyright } = data.prismicWebsiteLayout.data

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        style={
          uid === "retain"
            ? [
                {
                  cssText: `.async-hide { opacity: 0 !important}`,
                },
              ]
            : []
        }
      >
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta
          name="google-site-verification"
          content="IRdl643QG669GnneNd_Cvcu3MSutVoYsCtOKDWVe94Q"
        ></meta>
        {uid === "retain" && (
          <script>
            {`
              (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
              h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
              (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
              })(window,document.documentElement,'async-hide','dataLayer',4000,
              {'GTM-M7VNZZ':true});
            `}
          </script>
        )}
        <script>
          {`
          "use strict";
                (function() { var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true; po.src = '//api.at.getsocial.io/widget/v1/gs_async.js?id=8e2137'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();

                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:981655,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        {/*<script*/}
        {/*  type="text/javascript"*/}
        {/*  src="https://cdn.cookielaw.org/consent/bff98548-2879-451a-90c1-638a780999b1/OtAutoBlock.js"*/}
        {/*/>*/}
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="bff98548-2879-451a-90c1-638a780999b1"
        />
      </Helmet>
      <Navigation data={navigationData} colorTheme={colorTheme} />
      <Link to={"/contact-us/"}>
        <p className="persistent-contact uk-text-center uk-background-primary uk-light uk-visible@m">
          <span className="uk-display-block" data-uk-icon="icon: mail; ratio: 1.5" />
          Let's talk
        </p>
      </Link>
      <main>{children}</main>
      <Footer data={footerData} copyright={copyright.raw} whiteLogo={whiteLogo} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
